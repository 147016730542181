<template>
  <poll-test-result
    v-if="moduleType && currentTestId"
    :locale="locale"
    :archive-inactive-tests="archiveInactiveTests"
    :poll-chain="pollChain"
    :test-results="testResults"
    :module-type="moduleType"
  ></poll-test-result>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { polls, room } from "@/store/modules/store.namespaces";
import { GET_TEST_RESULTS, SHOW_POLL_TEST_RESULT } from "@/store/modules/polls/action-types";
import BasePollContainer from "./components/BasePollContainer";
import PollTestResult from "./components/PollTestResult";

export default {
  name: "PollTestResultContainer",
  components: {
    PollTestResult,
  },
  extends: BasePollContainer,
  computed: {
    ...mapState(room, {
      archiveInactiveTests: state => state.roomInfo?.archive_inactive_tests ?? 0,
      locale: state => state.interfaceLanguage,
    }),
    pollChain() {
      return this.moduleType && this.$store.state.polls[this.moduleType].chain;
    },
    currentTestId() {
      return this.moduleType && this.$store.state.polls[this.moduleType].currentResultId;
    },
    testResults() {
      return (
        (this.currentTestId &&
          this.moduleType &&
          this.$store.state.polls[this.moduleType].results[this.currentTestId]) ||
        {}
      );
    },
  },
  async created() {
    if (!this.pollChain && !this.currentTestId) {
      await this.getTestResults({
        type: this.moduleType,
        payload: this.testId,
      });
    }
  },
  methods: {
    ...mapActions(polls, {
      getTestResults: GET_TEST_RESULTS,
      showTestResult: SHOW_POLL_TEST_RESULT,
    }),
  },
};
</script>
