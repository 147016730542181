<template>
  <div :class="[$style.wrapper, classes, displayClass]" class="uk-flex align-center">
    <ws-icon v-if="!showCheckIcon" regular lg :class="['test-result-item__icon', `test-result-item__icon--${status}`, $style.icon]">
      {{ icon }}
    </ws-icon>

    <div v-if="type === 'choose_wrong'">
      <img :src="answer.title" :class="$style.choose_wrong" :alt="question.title" />
    </div>

    <div
      v-else-if="type === 'question_correlation'"
      :class="[$style.correlation, $style[`${status}`]]"
    >
      <img v-if="question.is_image_correlation" :src="answer.leftSide.title" alt="" />
      <p v-else>{{ tagParse(answer.leftSide.title) }}</p>

      <hr :class="[$style.line, $style[`${status}`]]" />

      <p>{{ tagParse(answer.rightSide.title) }}</p>
    </div>

    <div
      v-else-if="type === 'analog_scale' && !isRating"
      class="uk-flex-middle uk-text-center"
      :class="$style.analogScale"
    >
      {{ answer.title || $t("Tests.noAnswer") }}
      <div class="uk-flex uk-flex-center uk-padding">
        <ws-radio
          v-for="i in +answer.analog_scale_step"
          :key="i"
          :class="$style.radio"
          :current-value="+yourAnswers[index].title"
          :value="i"
          :disabled="+yourAnswers[index].title !== i"
        />
      </div>
      {{ answer.description || $t("Tests.noAnswer") }}
    </div>

    <div v-else-if="type === 'gradient_scale'" :class="$style.answer">
      <div :class="$style.answer">
        <b>{{ $t("Tests.yourAnswer") }}</b>
        <span>{{ tagParse(yourAnswers[0].title) || $t("Tests.noAnswer") }}</span>
      </div>
    </div>

    <div v-else-if="type === 'analog_scale' && isRating" :class="$style.answer">
      <b>{{ $t("Tests.yourAnswer") }}</b>
      <span>{{ tagParse(yourAnswers[0].title) || $t("Tests.noAnswer") }}</span>
    </div>

    <div v-else-if="type === 'open_answer'" :class="$style.answer">
      {{ tagParse(answer.title) }}
    </div>

    <div v-else :class="$style.answer">
      {{ tagParse(answer.title) }}
    </div>
  </div>
</template>

<script>
import { hyphenateSync } from "hyphen/ru";
import WsIcon from "@/components/base/WsIcon";
import { POLL_STATUS_ICONS, PollStatus } from "@/constants/polls/poll-types";
import WsRadio from "@/components/base/WsRadio";

export default {
  name: "PollTestResultAnswerItem",
  components: {
    WsIcon,
    WsRadio,
  },
  props: {
    // TODO: Убрать остальные параметры, завязанные на question
    // и сделать все через него
    question: {
      type: Object,
      required: true,
    },
    answer: {
      type: [String, Object],
      required: true,
    },
    type: {
      type: String,
      default: "",
    },
    isRating: {
      type: [Boolean, Number],
      default: false,
    },
    hasPoints: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
    yourAnswers: {
      type: Array,
      default: () => [],
    },
    rightAnswers: {
      type: Array,
      default: () => [],
    },
    showDetails: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
    relationType: {
      type: Boolean,
      default: false,
    },
    openType: {
      type: Boolean,
      default: false,
    },
    answered: {
      type: Boolean,
      default: false,
    },
    hasRightAnswers: {
      type: Boolean,
      default: false,
    },
    testType: {
      type: String,
      default: "survey",
    },
  },
  computed: {
    answerText() {
      if (this.answered && !this.yourAnswersHasAnswer && this.relationType && !this.showDetails) {
        return this.yourAnswers[this.index];
      }
      return this.answer.title;
    },
    yourAnswersHasAnswer() {
      return this.yourAnswers.some(answer => answer?.id === this.answer.id);
    },
    rightAnswersHasAnswer() {
      return this.rightAnswers.some(answer => answer?.id === this.answer.id);
    },
    relationAnswerCorrect() {
      return (
        this.relationType && this.yourAnswers[this.index]?.id === this.rightAnswers[this.index]?.id
      );
    },
    /**
     * Метод генерации статуса вопроса.
     *
     * @returns {string} Статус вопроса (правильный/неправильный/не проверен)
     */
    status() {
      if (this.testType === "survey")
        return this.question.your_answer.some(item => item.id === this.answer.id)
          ? PollStatus.ANSWERED
          : PollStatus.NOT_CHECKED;

      if (this.type === "question_correlation") {
        const isCorrect = this.question?.right_answer?.some(item => {
          return (
            this.answer?.leftSide?.title === item?.leftSide &&
            this.answer?.rightSide?.title === item?.rightSide
          );
        });

        return this.showDetails
          ? isCorrect
            ? PollStatus.CORRECT_MUTED
            : PollStatus.WRONG_MUTED
          : isCorrect
          ? PollStatus.CORRECT
          : PollStatus.WRONG;
      }

      if (!this.answered) {
        return PollStatus.WRONG_MUTED;
      }

      if (this.right && this.openType) {
        if (this.hasPoints && !this.showDetails) {
          return PollStatus.CORRECT;
        }
        return PollStatus.CORRECT_MUTED;
      }

      if (this.showDetails) {
        return !this.yourAnswersHasAnswer ? PollStatus.NOT_CHECKED : PollStatus.CORRECT_MUTED;
      }

      if (this.relationType) {
        return this.getStatusForRelationType();
      }

      if (!this.yourAnswersHasAnswer) {
        return PollStatus.NOT_CHECKED;
      }

      if (!this.hasRightAnswers) {
        return PollStatus.CORRECT_MUTED;
      }

      if (this.rightAnswersHasAnswer) {
        return PollStatus.CORRECT;
      }

      if (!this.question.right && this.openType && !this.question.points) {
        return PollStatus.WRONG_MUTED;
      }

      return PollStatus.WRONG;
    },
    /**
     * Класс стилей для элемента.
     *
     * @returns {string} - классы стилей у элемента.
     */
    classes() {
      return this.$style[`wrapper_${this.status}`];
    },
    /**
     * Какой класс display должен быть у элемента.
     *
     * @returns {string} - класс дисплея block/flex
     */
    displayClass() {
      return this.type !== "analog_scale" || this.type !== "gradient_scale" ? "block" : "flex";
    },
    /**
     * Должна ли быть иконка у данного вопроса
     *
     * @returns {boolean} - должна ли быть иконка
     */
    showCheckIcon() {
      return ["question_correlation", "analog_scale", "gradient_scale"].includes(this.type);
    },
    /**
     * Вид иконки
     *
     * @returns {string} - статус, от которого будет меняться иконка
     */
    icon() {
      return POLL_STATUS_ICONS[this.status];
    },
  },
  methods: {
    getStatusForRelationType() {
      if (
        (!this.yourAnswersHasAnswer || this.rightAnswersHasAnswer) &&
        this.relationAnswerCorrect
      ) {
        return PollStatus.CORRECT;
      }
      return PollStatus.WRONG;
    },
    /**
     * Метод обработки HTML-тегов
     *
     * @param {string} string - строка для обработки.
     * @returns {string} - обработанная строка.
     */
    tagParse(string) {
      return string
        ? hyphenateSync(string.replace(/&lt;/gi, "<").replace(/&gt;/gi, ">"))
        : this.$t("Tests.notAnswered");
    },
  },
};
</script>

<style lang="scss" module>
.icon {
  margin-right: 10px;
}

.wrapper {
  position: relative;
  min-height: 18px;
  margin-bottom: 14px;
  font-size: 15px;
  line-height: 18px;
  color: var(--base-text-color);
  text-align: left;
  word-break: break-word;

  &_correct .icon,
  &_correct .answer {
    color: var(--positive-color);
  }

  .answer {
    display: inline-block;
    width: calc(100% - 40px);
    white-space: break-spaces;
  }

  &_wrong .icon,
  &_wrong .answer {
    color: var(--negative-color);
  }

  &_notChecked .icon,
  &_correctMuted .icon,
  &_correctMuted .answer,
  &_wrongMuted .icon,
  &_wrongMuted .answer {
    color: $gray-dark;
  }

  &_answered {
    .answer {
      font-size: 1.3em;
      font-weight: bold;
    }
  }

  .correlation {
    display: grid;
    grid-template-columns: 45% 10% 45%;
    align-items: center;
    width: 100%;

    img,
    p {
      border-radius: 10px;
      border: solid 2px $gray-dark;
      padding: 10px;
      max-height: 400px;
      overflow: auto;
      height: 100%;
      margin-top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.wrong {
      img,
      p {
        border-color: var(--negative-color);
      }
    }

    &.correct {
      img,
      p {
        border-color: var(--positive-color);
      }
    }

    img {
      width: 100%;
      object-fit: cover;
    }

    .line {
      border: 0;
      height: 2px;
      width: 100%;
      background: $gray-dark;
      margin-top: 0;

      &.wrong {
        background: var(--negative-color);
      }

      &.correct {
        background: var(--positive-color);
      }
    }
  }
}

.analogScale {
  display: grid;
  padding: 10px 0 0 0;
  grid-template-columns: 15% 70% 15%;
}

.gradientScale {
  padding-top: 10px;
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
  width: 100%;
}

.radio {
  margin-right: 5px;
}

.choose_wrong {
  max-height: 100px;
}
</style>
