<template>
  <div class="test-result-item__question">
    <div
      class="test-result-item__toggle icon-arrow-right"
      :class="opened ? 'test-result-item__toggle--open' : ''"
      @click="toggle"
    >
      <div class="test-result-item__question-number">
        {{ orderNumber }}
        <span
          v-if="!showDetails && results.test_type !== 'survey'"
          class="test-result-item__points"
        >
          {{ question.user_points | roundValue }}
          {{ pointsText }}
        </span>
        <ws-icon
          v-if="!showDetails && results.test_type !== 'survey'"
          lg
          regular
          :class="['test-result-item__icon', `test-result-item__icon--${status}`]"
        >
          {{ icon }}
        </ws-icon>
      </div>
    </div>
    <slide-up-down :active="opened">
      <rating v-if="isRatingType" :chart="question"></rating>
      <div v-else class="test-result-item__content">
        <div class="test-result-item__text">{{ hyphenate(question.title) }}</div>
        <div class="test-result-item__variant-text">
          {{ hasAnswer ? $t("testNGames.youAnswered") : $t("Tests.noAnswer") }}
        </div>
        <div v-if="hasAnswer">
          <poll-test-result-answer-item
            v-for="(answer, idx) in answers"
            :key="idx"
            :answer="answer"
            :test-type="results.test_type"
            :has-points="!!question.points"
            :type="question.type"
            :is-rating="question.is_rating"
            :right="question.right"
            :your-answers="question.your_answer"
            :right-answers="question.is_right"
            :answered="question.answered"
            :question="question"
            :show-details="showDetails"
            :index="idx"
            :relation-type="isRelationType"
            :open-type="isOpenType"
            :has-right-answers="hasRightAnswers"
          ></poll-test-result-answer-item>
        </div>
      </div>
    </slide-up-down>
  </div>
</template>

<script>
import { hyphenateSync } from "hyphen/ru";
import SlideUpDown from "vue-slide-up-down";
import { i18nPlurals } from "@/utils";
import Rating from "@/components/common/diagrams/Rating/Rating";
import WsIcon from "@/components/base/WsIcon";
import { POLL_STATUS_ICONS, PollStatus, PollType } from "@/constants/polls/poll-types";
import PollTestResultAnswerItem from "./PollTestResultAnswerItem";

export default {
  name: "PollTestResultItem",
  components: {
    SlideUpDown,
    Rating,
    PollTestResultAnswerItem,
    WsIcon,
  },
  filters: {
    roundValue(val) {
      if (Number.isInteger(val)) {
        return val;
      }
      return val.toFixed(2);
    },
  },
  props: {
    // Вопрос
    question: {
      type: Object,
      required: true,
    },
    // Ответы на вопрос
    results: {
      type: Object,
      required: true,
    },
    // Показываются ли детали пользователю
    showDetails: {
      type: Boolean,
      default: false,
    },
    // Локализация
    locale: {
      type: String,
      default: "rus",
    },
    // Номер ответа
    index: {
      type: Number,
    },
  },
  data() {
    return {
      // Раскрыт ли ответ
      opened: false,
    };
  },
  computed: {
    correctMuted() {
      if (this.isChooseWrong) {
        return !Object.values(this.question.is_right)?.length;
      }
      return !this.question.is_right?.length || (this.isOpenType && !this.question.points);
    },
    wrongMuted() {
      if (this.isOpenType && !this.question.points) {
        return true;
      }

      return !this.question.your_answer?.length;
    },
    /**
     * Статус ответа
     *
     * @returns { string } - статус ответа, правильный/неправильный/частично-верный
     */
    status() {
      if (!this.question.answered) {
        return PollStatus.WRONG_MUTED;
      }

      if (this.question.user_points && this.partiallyRight) {
        return PollStatus.PARTIALLY;
      }

      if (this.question.user_points && this.question.user_points !== this.question.points) {
        return PollStatus.PARTIALLY;
      }

      if (this.question.right) {
        if (this.correctMuted) {
          return PollStatus.CORRECT_MUTED;
        }
        return PollStatus.CORRECT;
      }

      if (!this.question.right && !this.partiallyRight) {
        if (this.wrongMuted) {
          return PollStatus.WRONG_MUTED;
        }
        return PollStatus.WRONG;
      }

      if (!this.question.user_points) return PollStatus.WRONG;

      return "";
    },
    /**
     * Вид иконки
     *
     * @returns {string} - статус, от которого будет меняться иконка
     */
    icon() {
      return POLL_STATUS_ICONS[this.status];
    },
    pointsText() {
      return i18nPlurals("point", this.question.points, this.locale);
    },
    orderNumber() {
      return `${this.$t("testNGames.question")} №${this.index + 1}`;
    },
    /**
     * Метод генерации ответов на вопросы.
     *
     * TODO: Не знаю зачем использовать этот метод если можно перебирать параметр your_answer
     * возможно его стоит убрать.
     *
     * @returns {Array} Возваращает массив ответов на вопрос
     */
    answers() {
      if (this.question.answered && !this.showDetails && this.isChooseWrong) {
        return this.question.variants.filter(variant => {
          return this.question.your_answer.some(item => item.id === variant.id);
        });
      }

      if (
        this.question.type === PollType.RELATION_IMAGE ||
        this.question.type === PollType.RELATION ||
        this.question.type === PollType.OPEN ||
        this.question.type === PollType.WORD_CLOUD ||
        this.question.type === PollType.GRADIENT_SCALE
      ) {
        return this.question?.your_answer;
      }

      return this.question.variants;
    },
    partiallyRight() {
      if (!this.question.answered) {
        return false;
      }
      const { your_answer, is_right } = this.question;
      if (this.isRelationType) {
        return (
          !this.question.right &&
          this.answers.some((answer, idx) => your_answer[idx].id === is_right[idx].id) &&
          this.answers.some((answer, idx) => your_answer[idx].id !== is_right[idx].id)
        );
      }
      return (
        !this.question.right &&
        this.answers.some(
          answer =>
            your_answer.some(item => item.id === answer.id) &&
            is_right.some(item => item.id === answer.id),
        )
      );
    },
    isRelationType() {
      return (
        this.question.type === PollType.RELATION_IMAGE || this.question.type === PollType.RELATION
      );
    },
    isOpenType() {
      return this.question.type === PollType.OPEN;
    },
    isRatingType() {
      return this.question.type === PollType.RATING;
    },
    isChooseWrong() {
      return this.question.type === PollType.CHOOSE_WRONG;
    },
    hasRightAnswers() {
      if (!this.question.answered) return false;
      if (this.isChooseWrong) {
        return !!Object.values(this.question.is_right)?.length;
      }
      return !!this.question.is_right?.length;
    },
    /**
     * Проверка вопроса на наличие ответа.
     * Если у your_answer[0] отсутствует id - значит, что вопрос был пропущен и ответа на него не было.
     *
     * @returns {string|boolean} - id/текст ответа
     */
    hasAnswer() {
      if (
        this.question.type === PollType.RELATION_IMAGE ||
        this.question.type === PollType.RELATION
      )
        return this.question?.your_answer[0]?.leftSide.id;

      if (this.question.type === PollType.OPEN) {
        return this.question?.your_answer?.length && this.question?.your_answer[0]?.title.length;
      }

      return this.question?.your_answer?.length && this.question.type === PollType.WORD_CLOUD
        ? this.question?.your_answer[0]?.title // так как у облака вопросов у ответа id == null.
        : this.question?.your_answer[0]?.id;
    },
  },
  mounted() {
    if (this.index === 0) {
      this.opened = true;
    }
  },
  methods: {
    toggle() {
      this.opened = !this.opened;
    },
    /**
     * Метод для постановки мягких переносов
     *
     * @param {string} text - текст для перевода.
     * @returns {string} - строка с мягкими переносами.
     */
    hyphenate(text) {
      return hyphenateSync(text);
    },
  },
};
</script>

<style lang="less" scoped>
.test-result-item {
  &__question {
    background-color: #ffffff;
    border: solid 1px var(--base-shadow-color);
    border-radius: 4px;

    &:not(:last-of-type) {
      margin-bottom: 12px;
    }
  }

  &__question-number {
    position: relative;
    height: 18px;
    padding-right: 30px;
    font-family: ProximaNova, sans-serif;
    font-size: 14px;
    line-height: 18px;
    color: #383838;
    text-align: center;
    transform: translateY(-5px);

    .test-result-item__points {
      position: absolute;
      bottom: -14px;
      left: 0;
      font-size: 12px;
      color: #bebebe;
      white-space: nowrap;
    }
  }

  &__toggle {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    font-size: 14px;
    color: var(--negative-color);
    cursor: pointer;
    user-select: none;

    &::before {
      position: absolute;
      top: 17px;
      right: 36px;
      font-size: 14px;
      color: var(--negative-color);
      transition: all ease 0.3s;
      transform: rotate(0deg);
    }

    &--open {
      &::before {
        color: var(--positive-color);
        transform: rotate(90deg);
      }
    }
  }

  &__content {
    box-sizing: border-box;
    max-width: 666px;
    padding: 20px;
    margin: 0 auto;
  }

  &__text {
    margin-bottom: 10px;
    font-size: 15px;
    line-height: 1.4;
    color: var(--base-text-color);
    text-align: left;
    text-overflow: ellipsis;
    white-space: break-spaces;
  }

  &__variant-text {
    height: 17px;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 17px;
    color: #949494;
    text-align: left;
  }

  &__icon {
    position: absolute;
    top: 5px;
    right: 0;
  }

  &__icon--correct {
    color: var(--positive-color);
  }

  /* Смешано именование для будущего рефакторинга */
  &__icon--correctMuted,
  &__icon--wrongMuted {
    color: #999999;
  }

  &__icon--partially {
    color: #f79843;
  }

  &__icon--wrong {
    color: var(--negative-color);
  }
}
</style>
