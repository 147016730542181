<template>
  <div>
    <loader v-if="!testResults" class="test-results-loader" />
    <div
      v-if="testResults"
      class="test-results"
      :class="{
        'test-results--with-thanks': !showResults,
      }"
    >
      <back-button @go-back="back"></back-button>
      <template v-if="showResults">
        <poll-test-result-details
          v-if="!testResults.is_show_detail_result"
          :results="testResults"
          :show-percent="showPercent"
          :locale="locale"
        ></poll-test-result-details>
        <div class="test-results__title">
          {{ hyphenate(testResults.test_name) }}
        </div>
        <div class="test-results__questions">
          <poll-test-result-item
            v-for="(question, index) in results"
            :key="index"
            :results="testResults"
            :question="question"
            :index="index"
            :show-details="testResults.is_show_detail_result"
            :locale="locale"
          />
        </div>
      </template>
      <poll-thanks-for-answer
        v-else-if="errorMessage"
        :show-label="errorMessage"
        :auto-close="false"
        :error="errorMessage"
        :custom-title="testResults.message || $t('SpeakerQuestions.notifs.error.title')"
        @close="back"
      />
      <poll-thanks-for-answer
        v-else
        :show-label="isInteractive"
        :auto-close="isInteractive"
        @close="back"
      />
    </div>
  </div>
</template>

<script>
import { hyphenateSync } from "hyphen/ru";
import { mapMutations, mapGetters } from "vuex";
import Loader from "@/components/common/elements/Loader";
import BackButton from "@/components/common/elements/BackButton";
import PollThanksForAnswer from "@/components/common/polls/components/PollThanksForAnswer";
import { CLEAR_POLLCHAIN } from "@/store/modules/polls/mutation-types";
import { polls, room } from "@/store/modules/store.namespaces";
import { interactive } from "@/constants/polls/polls-module-types";
import { PollType } from "@/constants/polls/poll-types";
import { AVAILABLE_ROOM_MODULES } from "@/store/modules/common/getter-types";
import PollTestResultItem from "./PollTestResultItem";
import PollTestResultDetails from "./PollTestResultDetails";

export default {
  name: "PollTestResult",
  components: {
    Loader,
    BackButton,
    PollTestResultDetails,
    PollTestResultItem,
    PollThanksForAnswer,
  },
  props: {
    pollChain: {
      type: Object,
      default: null,
    },
    archiveInactiveTests: {
      type: Number,
      required: true,
    },
    testResults: {
      type: Object,
      required: true,
    },
    locale: {
      type: String,
      default: "rus",
    },
    moduleType: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters(room, {
      availableRoomModules: AVAILABLE_ROOM_MODULES,
    }),
    results() {
      if (!this.testResults) {
        return [];
      }
      const { questions, polls } = this.testResults;
      return [
        ...questions.map(question => ({ ...question, answered: true })),
        ...this.makeUnanswered(polls),
      ];
    },
    testQuestions() {
      if (!this.testResults) {
        return [];
      }

      return this.prepareResults();
    },
    showResults() {
      return this.testResults && !!Number(this.testResults.show_results_to_client);
    },
    /**
     * Проверка на наличие ошибки
     */
    errorMessage() {
      return this.testResults?.message && this.testResults?.error;
    },
    showPercent() {
      // параметр add отвечат на вопрос если в вопросе правильные ответы выставленые в админке
      return (
        this.testQuestions &&
        this.testQuestions.length &&
        this.testQuestions.some(question => question.add)
      );
    },
    isInteractive() {
      return this.moduleType === interactive;
    },
  },
  methods: {
    ...mapMutations(polls, {
      clearPollChain: CLEAR_POLLCHAIN,
    }),

    /**
     * Метод возврата на предыдущую страницу
     *
     * TODO: Перенести эту логику в router
     */
    back() {
      if (!this.availableRoomModules.workbook) {
        this.$router.push({ name: "presentation" });
      } else if (this.archiveInactiveTests && (!this.pollChain || !this.pollChain.testIsEndless)) {
        this.$router.push({ name: "tests", query: { showFinished: "true" } });
      } else {
        this.clearPollChain({
          type: this.moduleType,
          force: true,
        });
        this.$router.push({ name: "tests" });
      }
    },

    prepareResults() {
      const { questions, polls } = this.testResults;
      const unaswered = this.makeUnanswered(polls);
      let answered = [];
      if (!this.testResults.is_show_detail_result) {
        answered = questions.map(question => {
          const { your_answer, right_answer, variants, right, type } = question;
          let missed = false;
          const answersToShow = variants.reduce((accum, variant, idx) => {
            const answer = {
              text: variant,
              status: "wrong",
            };
            if (!your_answer.includes(variant)) {
              // случай когда в моих ответах не было этого вариант
              if (type === PollType.CHOOSE_WRONG) {
                // для вопроса на выбор лишнего мы выводим только изображение ответа
                return accum;
              }

              if (type === PollType.RELATION_IMAGE || type === PollType.RELATION) {
                answer.text = your_answer[idx];
                if (your_answer[idx] === right_answer[idx]) {
                  answer.status = "correct";
                }
              } else {
                answer.status = "notChecked";
              }
            } else if (right_answer.includes(variant) && right) {
              if (type === PollType.RELATION_IMAGE || type === PollType.RELATION) {
                if (your_answer[idx] === right_answer[idx]) {
                  answer.status = "correct";
                }
              } else {
                answer.status = "correct";
              }
            } else if (right_answer.includes(variant) && !right) {
              if (type === PollType.RELATION_IMAGE || type === PollType.RELATION) {
                if (your_answer[idx] === right_answer[idx]) {
                  answer.status = "correct";
                }
              } else {
                answer.status = "correct";
              }
              missed = true;
            }
            accum.push(answer);
            return accum;
          }, []);
          if (type === PollType.RELATION_IMAGE || type === PollType.RELATION) {
            missed =
              answersToShow.some(answr => answr.status === "correct") &&
              answersToShow.some(answr => answr.status === "wrong");
          }
          return { ...question, answers_to_show: answersToShow, missed };
        });
      } else {
        answered = questions.map(question => {
          const { your_answer, variants, type } = question;
          let missed = false;
          const answersToShow = variants.map(variant => {
            const answer = {
              text: variant,
              status: "correctMuted",
            };
            if (!your_answer.includes(variant)) {
              answer.status = "notChecked";
            }
            return answer;
          });
          if (type === PollType.RELATION_IMAGE || type === PollType.RELATION) {
            missed =
              answersToShow.some(answr => answr.status === "correctMuted") &&
              answersToShow.some(answr => answr.status === "notChecked");
          }
          return { ...question, answers_to_show: answersToShow, missed };
        });
      }

      return [...answered, ...unaswered];
    },
    /**
     * Мапим не отвеченные вопросы в нужную модель
     *
     * @param {Array} polls - не отвеченные вопросы
     * @returns {Array} - подготовленные не отвеченные вопросы
     */
    makeUnanswered(polls) {
      return polls.map(({ title, type, variants }) => {
        return {
          title,
          type,
          points: 0,
          answered: false,
          variants,
        };
      });
    },
    /**
     * Метод для постановки мягких переносов
     *
     * @param {string} text - текст для перевода.
     * @returns {string} - строка с мягкими переносами.
     */
    hyphenate(text) {
      return hyphenateSync(text);
    },
  },
};
</script>

<style scoped lang="less">
.test-results {
  position: relative;
  box-sizing: border-box;
  flex-shrink: 0;
  padding: 20px 20px 100px;

  &__title {
    min-height: 64px;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 300;
    hyphens: auto;
    line-height: 32px;
    color: #303030;
    text-align: center;
    word-break: break-all;
  }

  &__questions {
    max-width: 720px;
    margin: 0 auto;
  }

  &--with-thanks {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.test-results-loader {
  margin: 25% auto;

  @media (max-width: 640px) {
    padding-top: 56px;
  }
}
</style>
