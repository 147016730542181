<template>
  <div class="test-result">
    <div class="test-result__donut">
      <pie-chart
        legend="false"
        :library="{
          cutoutPercentage: 60,
          elements: { arc: { borderWidth: 0 } },
          tooltips: { enabled: false },
        }"
        width="140px"
        height="140px"
        :suffix="' ' + pointsText"
        :data="[
          [$t('testNGames.incorrectAnswers'), 100 - results.percent],
          [$t('testNGames.correctAnswers'), results.percent],
        ]"
        :colors="['#e8e9e8', '#209c29']"
        donut="true"
      ></pie-chart>
      <div class="test-result__donut-center">
        +{{ results.points }}
        <br />
        {{ pointsText }}
      </div>
    </div>
    <div v-if="showPercent && results.test_type == 'test'" class="test-result__text">
      {{ results.percent }}%
      {{ $t("testNGames.persentsCorrectAnswers") }}
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueChartkick from "vue-chartkick";
import Chart from "chart.js";
import { i18nPlurals } from "@/utils";

if (!Object.keys(Vue.options.components).includes("pie-chart")) {
  Vue.use(VueChartkick, {
    adapter: Chart,
  });
}

export default {
  name: "PollTestResultDetails",
  props: {
    results: {
      type: Object,
      required: true,
    },
    showPercent: {
      type: Boolean,
      default: true,
    },
    locale: {
      type: String,
      default: "rus",
    },
  },
  computed: {
    pointsText() {
      if (!this.results) {
        return this.$t("testNGames.point");
      }
      return i18nPlurals("point", this.results.points, this.locale);
    },
  },
};
</script>

<style lang="less" scoped>
.test-result {
  &__donut {
    position: relative;
    width: 140px;
    height: 140px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }
  &__donut-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 84px;
    height: 84px;
    box-sizing: border-box;
    padding-top: 20px;
    padding-bottom: 40px;
    line-height: 22px;
    text-align: center;
    overflow: hidden;
    border-radius: 50%;
    background-color: transparent;
    font-size: 18px;
    font-weight: 600;
    color: var(--positive-color);
  }
  &__text {
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    text-align: center;
    color: var(--positive-color);
    margin-bottom: 20px;
  }
  &__text {
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    text-align: center;
    color: var(--positive-color);
    margin-bottom: 20px;
  }
}
</style>
